.modal-content {
  position: relative;
  /* Ensure the modal content is relatively positioned */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  /* Semi-transparent white to not entirely block the modal content */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.circular-progress {
  transform: rotate(-90deg);
  /* Start the circle from the top */
}

.circular-progress circle {
  fill: none;
  stroke-width: 4;
  stroke-linecap: round;
}


.background {
  stroke: #e6e6e6;
}

.progress {
  stroke: #3498db;
  /* Change this to whatever color you prefer for the progress */
}