.secondary-nav {
  background-color: #ffffff; 
  padding: 0.5rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  margin-bottom: 0.5rem;
}

.secondary-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex; /* horizontally line up the tabs */
  gap: 0.5rem;
}

.secondary-nav li {
  margin: 0;
  padding: 0;
}

.secondary-nav a {
  display: inline-block;
  padding: 0.25rem 1rem;
  text-decoration: none;
  color: #333333; /* Light theme text color */
  background-color: #e0e0e0; 
  border: 1px solid #ccc; 
  border-radius: 0; /* Square corners */
  transition: background-color 0.2s, color 0.2s;
  font-weight: 500;
}

.secondary-nav a:hover {
  background-color: #d1d1d1;
}

.secondary-nav a.active {
  background-color: #007bff; /* or your brand color for light mode */
  color: #ffffff;
  border-color: #007bff;
  cursor: default;
}


.dark-mode .secondary-nav {
  /* Make the nav background darker to match the rest of the interface */
  background-color: #343a40;
  box-shadow: none; /* or a darker shadow if you like */
}

.dark-mode .secondary-nav a {
  /* Dark “unselected” tab color, plus a subtle border */
  background-color: #3A3A3A;
  border-color: #444;
  color: #CCCCCC; /* Lighter text for contrast */
}

.dark-mode .secondary-nav a:hover {
  /* Slightly lighter on hover, still in dark range */
  background-color: #444444;
}

.dark-mode .secondary-nav a.active {
  /* Use your accent color for the active tab (e.g., the orange/red from the header) */
  background-color: #3f6791; 
  border-color: #3f6791;
  color: #FFFFFF;
  cursor: default;
}
