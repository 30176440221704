@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css'; 
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';

@import '~react-toastify/dist/ReactToastify.min.css';
@import '~react-tooltip/dist/react-tooltip.css';

#root {
  width: 100vw;
  height: 100vh;
}

.main-header .navbar-nav .nav-item .nav-link {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.content-wrapper,
.main-header,
.main-footer,
.navbar-blue,
.card,
.nav-link.active,
.btn,
.form-control {
  transition: all .1s ease-in-out, margin-left .3s ease-in-out !important;
}

.dark-mode .content-wrapper,
.dark-mode .main-header,
.dark-mode .main-footer,
.dark-mode .navbar-blue,
.dark-mode .card,
.dark-mode .nav-link.active,
.dark-mode .btn,
.dark-mode .form-control {
  transition: all .1s ease-in-out, margin-left .3s ease-in-out !important;
}