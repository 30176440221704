/* Light Mode (Default) Styles */
.permission-container {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}

h1 {
  font-size: 24px;
  color: #0073e6;
  margin-bottom: 20px;
}

.tree-node {
  margin-bottom: 15px;
  border-left: 2px solid #ccc;
  padding-left: 10px;
}

.node-header {
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.node-header.faded-text {
  opacity: 0.5;
}

.permissions {
  display: flex;
  gap: 15px;
  margin-top: 5px;
  font-size: 12px;
}

.permissions label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.checkbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  margin: 0 5px;
  cursor: pointer;
}

.checkbox.accept {
  background-color: #4caf50;
  border: 1px solid #388e3c;
}

.checkbox.deny {
  background-color: #f44336;
  border: 1px solid #d32f2f;
}

.checkbox.inherit {
  background-color: #ff9800;
  border: 1px solid #f57c00;
}

.checkbox:hover {
  opacity: 0.8;
}

.checkbox:active {
  opacity: 0.6;
}

.tree-node.created-node {
  border: 2px solid #4caf50;
  padding: 5px;
  border-radius: 4px;
  background-color: #e8f5e9;
}

.tree-node.deleted-node {
  border: 2px dashed #f44336;
  padding: 5px;
  border-radius: 4px;
  background-color: #ffe6e6;
}

.tree-node.updated-node {
  border: 2px solid #0073e6;
  padding: 5px;
  border-radius: 4px;
  background-color: #e3f2fd;
}

.children {
  margin-left: 20px;
}

/* Dark Mode Styles */
body.dark-mode .permission-container {
  color: #e0e0e0;
  background-color: #1e1e1e;
}

body.dark-mode h1 {
  color: #80d4ff;
}

body.dark-mode .tree-node {
  border-left: 2px solid #555;
}

body.dark-mode .node-header {
  color: #e0e0e0;
}

body.dark-mode .node-header.faded-text {
  opacity: 0.5;
}

body.dark-mode .permissions label {
  color: #cfcfcf;
}

body.dark-mode .checkbox.accept {
  background-color: #4caf50;
  border: 1px solid #388e3c;
}

body.dark-mode .checkbox.deny {
  background-color: #f44336;
  border: 1px solid #d32f2f;
}

body.dark-mode .checkbox.inherit {
  background-color: #ff9800;
  border: 1px solid #f57c00;
}

body.dark-mode .tree-node.created-node {
  border: 2px solid #4caf50;
  background-color: #264d28;
}

body.dark-mode .tree-node.deleted-node {
  border: 2px dashed #f44336;
  background-color: #4d2626;
}

body.dark-mode .tree-node.updated-node {
  border: 2px solid #0073e6;
  background-color: #1c3d5a;
}

body.dark-mode .children {
  margin-left: 20px;
}
